<template>
  <div>
        <b-button
            id="buttonStatusId"          
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="`flat-${statusReview ? 'success' : 'danger'}`"
            @click="updateStatus(statusReview)"
        >
            <feather-icon :icon="statusReview ? 'CheckIcon': 'XIcon'" /> Revisado
        </b-button>
  </div>
</template>

<script>

    import Ripple from 'vue-ripple-directive';

    export default {
        props: {
            dataReview: Object
        },
        directives: {
            Ripple
        },
        data() {
            return {
                statusReview: false
            }
        },
        watch: {
            dataReview() {
                this.statusReview = this.dataReview.status;      
            }
        },
        methods: {
            updateStatus(status){            
                this.statusReview = !status;
                let data = {
                    status: this.statusReview,
                    i: this.dataReview.id
                }
                this.$emit("update-status", data);            
            }
        }
    }

</script>